<template>
  <div class="health-punch">
    <div class="punch">
      <div class="punch_item" @click="punchShow=true">
        <div class="item_btn">立即打卡</div>
        <div class="item_date">{{nowDate}}</div>
        <div class="time">打卡日期：{{startDate}} 至 {{endDate}}</div>
      </div>
    </div>
    <van-dialog title="健康打卡" v-model="punchShow" show-cancel-button confirmButtonText="打卡" confirmButtonColor="#4581F8" @confirm="submit">
      <van-cell-group style="padding:10px 0">
        <van-field v-model="dataForm.temperature" label="今日体温" placeholder="请输入体温" input-align="right" required clearable type="number">
          <template #right-icon><span style="color:#333">℃</span></template>
        </van-field>
        <van-cell class="health-select">
          <template #title>
            <span class="required">今日健康情况</span>
          </template>
          <template #right-icon>
            <van-radio-group v-model="dataForm.health" class="van-cell__value" direction="horizontal" style="position:absolute;right:0">
              <van-radio :name="0" icon-size="16px">健康</van-radio>
              <van-radio :name="1" icon-size="16px">非健康</van-radio>
            </van-radio-group>
          </template>
        </van-cell>
        <van-cell v-if="dataForm.health == 1">
          <template #title>
            <span class="required">请选择是否有如下症状</span>
          </template>
          <template #label>
            <van-checkbox-group v-model="dataForm.symptom" direction="horizontal" icon-size="14px">
              <van-checkbox name="fever" shape="square">发烧</van-checkbox>
              <van-checkbox name="cough" shape="square">咳嗽</van-checkbox>
              <van-checkbox name="weak" shape="square">乏力</van-checkbox>
              <van-checkbox name="symptoms" shape="square">其他症状</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="tips">为配合国家新冠疫情防控工作，请如实填报，谢谢您的配合！</div>
    </van-dialog>
    <el-calendar>
      <template slot="dateCell" slot-scope="{data}">
        <div class="day" :class="getClass(data.day)"> 
          {{ data.day.split('-')[2] }} <div class="need" v-if="checkDateList.indexOf(data.day) > -1"></div>
        </div>
      </template>
    </el-calendar>
    <div class="tip">注：带蓝色角标为需做核酸检测日期</div>
  </div>
  
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    var date = new Date()
    return {
      dataForm: {
        temperature: '',
        health: '',
        symptom: []
      },
      punchShow: false,
      startDate: '',
      endDate: '',
      finishedDate: [],
      checkDateList: [],
      dangerDate: [],
      nowDate: date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '-' + date.getDate()
    }
  },
  computed: {
    ...mapState(['houseId']),
  },
  created () {
    this.$http({
      url: this.$http.adornUrl('/wxapp/disease/observation/infoByUser'),
      method: 'get',
      params: this.$http.adornParams({
        userId: this.$route.query.userId,
        houseId: this.houseId
      })
    }).then(({data}) => {
      if (data && data.code === 0) {
        this.checkDateList = data.resVO.checkDateList
        this.startDate = data.resVO.isolationDate
        this.endDate = data.resVO.isolationEndDate
        data.resVO.isolationObservations.map(item => {
          if (item.health == 1) {
            this.dangerDate.push(item.observationDate.split(' ')[0])
          } else {
            this.finishedDate.push(item.observationDate.split(' ')[0])
          }
        })
      } else {
        this.$toast.fail(data.msg)
      }
    })
  },
  methods: {
    getClass (date) {
      if (this.finishedDate.indexOf(date) > -1) {
        return 'finished'
      } else if (this.dangerDate.indexOf(date) > -1) {
        return 'danger'
      } else if (new Date(this.startDate) <= new Date(date) && new Date(this.endDate) >= new Date(date)) {
        return 'unfinished'
      }
    },
    submit () {
      if (!this.dataForm.temperature) {return this.$toast.fail('失败:请输入体温');}
      if (this.dataForm.health === '') {return this.$toast.fail('失败:选择今日健康情况');}
      if (this.dataForm.health == 1 && this.dataForm.symptom.length == 0) {return this.$toast.fail('失败:请选择症状');}
      this.$http({
        url: this.$http.adornUrl('/wxapp/disease/observation/save'),
        method: 'post',
        data: this.$http.adornData({
          userId: this.$route.query.userId,
          resName: this.$userName,
          reporter: this.$userId,
          houseId: this.houseId,
          temperature: this.dataForm.temperature,
          health: this.dataForm.health,
          fever: this.dataForm.symptom.indexOf('fever') > -1 ? 1 : 0,
          cough: this.dataForm.symptom.indexOf('cough') > -1 ? 1 : 0,
          weak: this.dataForm.symptom.indexOf('weak') > -1 ? 1 : 0,
          symptoms: this.dataForm.symptom.indexOf('symptoms') > -1 ? 1 : 0
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$router.push({path: './punch-result', query: {health: this.dataForm.health}})
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background-color:transparent')
  }
}
</script>
<style scoped>
.health-punch >>> .van-dialog__header {
  padding: 30px 0;
  font-weight: 600;
}
.health-select >>> .van-cell__title, .van-cell__value {
  -webkit-box-flex: none;
  -webkit-flex: none;
  flex: none;
}
.title {
  font-size: 16px;
  margin-bottom: 20px;
}
.tips {
  line-height: 80px;
  transform: scale(0.95);
  margin: 0 auto;
  width: 120%;
  font-size: 24px;
  color: #ED4747;
}
.health-punch >>> .van-checkbox__label {
  font-size: 26px;
}
.health-punch >>> .el-calendar {
  font-size: 26px;
}
.health-punch >>> .el-calendar-table .el-calendar-day {
  padding: 0;
  height: auto;
}
.health-punch >>> .el-calendar-table td {
  border: none;
}
.health-punch >>> .el-calendar-table tr td:first-child {
  border: none;
}
.health-punch >>> .el-calendar-table tr:first-child td {
  border: none;
}
.health-punch >>> .el-calendar-table .el-calendar-day:hover {
  background-color: transparent;
}
.health-punch >>> .el-calendar-table td.is-selected {
  background-color: transparent;
}
.health-punch >>> .el-button--mini, .el-button--small {
  line-height: 30px;
  font-size: 26px;
}
.day {
  text-align: center;
  line-height: 70px;
  width: 70px;
  border-radius: 5px;
  margin: 10px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
}
.need {
  position: absolute;
  top: -21px;
  right: -21px;
  width: 40px;
  height: 40px;
  transform: rotate(-50deg);
  -webkit-transform: rotate(-50deg);
  -moz-transform: rotate(-50deg);
  -ms-transform: rotate(-50deg);
  background-color: #4581F8;
}
.finished {
  background-color: #18BF62;
  color: #fff;
}
.danger {
  background-color: #FF6262;
  color: #fff;
}
.unfinished {
  background-color: #F0F0F0;
  /* color: #fff; */
}
</style>
<style scoped lang="scss">
.health-punch {
  margin: 25px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}
.punch {
  .punch_item {
    color: #fff;
    margin: 40px auto;
    width: 400px;
    height: 400px;
    background-image: url('~@/assets/img/health-punch-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .item_btn {
      font-size: 30px;
      line-height: 50px;
    }
    .item_date {
      font-size: 26px;
      line-height: 50px;
    }
    .time {
      font-size: 26px;
      width: 600px;
      text-align: center;
      color: #999;
      position: absolute;
      bottom: 20px;
    }
  }
}
.tip {
  font-size: 26px;
  color: #4581F8;
  width: 100%;
  text-align: right;
  padding-right: 40px;
  padding-bottom: 30px;
}
.required {
  position: relative;
  &::before {
    content: '*';
    color: #ED4747;
    position: absolute;
    left: -16px;
  }
}
</style>